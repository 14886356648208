import axios from "axios";

import { authSelector, logout as logoutAction } from "./ducks/authDuck";
import { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import store from "./store";
import { EnhancedStore } from "@reduxjs/toolkit";



const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});


export const setupInterceptors = (store: EnhancedStore) => {

    const onResponse = (response: AxiosResponse): AxiosResponse => {
        //console.info(`[response] [${JSON.stringify(response)}]`);
        return response;
    }

    const onResponseError = (error: AxiosError): Promise<AxiosError> => {
        console.log(`[response error] `, error);
        if (error.request.status === 401 && error.config?.url != `${baseURL}${changePassword}` && error.config?.url != `${baseURL}${resetPassword}`) {
            console.log("Log out")
            store.dispatch(logoutAction());
        }
        return Promise.reject(error);
    }

    axiosInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
        const token = store.getState().auth.token;
        if (token) {
            config.headers.Authorization = `Token ${token}`
        }
        if (config.url === `${baseURL}${login}` /*|| config.url === `${baseURL}${logout}` || config.url === `${baseURL}${logoutAll}`*/ || config.url === `${baseURL}${resetPassword}`) {
            config.headers.Authorization = null;
        }
        return config;
    });

    axiosInstance.interceptors.response.use(onResponse, onResponseError);
}


const requests = () => {
    return {
        get: (url: any, params: any) => axiosInstance.get(url, params).then(res => res),
        post: (url: any, body: any, params: any) => axiosInstance.post(url, body, params).then(res => res),
        postRaw: (url: any, body: any, params: any) => axiosInstance.post(url, body, params),
        put: (url: any, body: any, params: any) => axiosInstance.put(url, body, params).then(res => res),
        patch: (url: any, body: any, params: any) => axiosInstance.patch(url, body, params).then(res => res),
        delete: (url: any, params: any) => axiosInstance.delete(url, params).then(res => res),
    }
}


const baseURL = "https://" + process.env.REACT_APP_DEV_API_HOSTNAME + "/api/v3"

const login = "/login"
const logout = "/logout"
const logoutAll = "/logout-all"
const changePassword = "/change-password"
const resetPassword = "/password-reset"
const user = "/user"
const chatUrl = "/conversation"
const rephrase = "/conversation/rephrase"
const mock = "/conversation/static"
const abort = "/conversation/abort"
const feedback = "/feedback"


export const ChatApi = {
    get: (conversationid: String, config: any) =>
        requests().get(`${baseURL}${chatUrl}/${conversationid}`, {
            ...config
        }),
    getAll: (config: any) =>
        requests().get(`${baseURL}${chatUrl}`, {
            ...config
        }),
    create: (body: any, config: any) =>
        requests().post(`${baseURL}${chatUrl}`, body, {
            ...config
        }),
    post: (conversationid: String, body: any, config: any) =>
        requests().post(`${baseURL}${chatUrl}/${conversationid}`, body, {
            ...config
        }),
    rename: (conversationid: String, body: any, config: any) =>
        requests().patch(`${baseURL}${chatUrl}/${conversationid}`, body, {
            ...config
        }),
    delete: (conversationid: String, config: any) =>
        requests().delete(`${baseURL}${chatUrl}/${conversationid}`, {
            ...config
        }),
    feedback: (conversationid: String, body: any, config: any) =>
        requests().post(`${baseURL}${feedback}/${conversationid}`, body, {
            ...config
        }),

    mock: (body: any, config: any) =>
        requests().post(`${baseURL}${mock}`, body, {
            ...config
        }),

    generalFeedback: (body: any, config: any) =>
        requests().post(`${baseURL}${feedback}`, body, {
            ...config
        }),
    rephraseWithouSave: (body: any, config: any) =>
        requests().post(`${baseURL}${rephrase}`, body, {
            ...config
        }),
    rephraseWithSave: (id: string, body: any, config: any) =>
        requests().post(`${baseURL}${rephrase}/${id}`, body, {
            ...config
        }),
    abort: (conversationid: String, config: any) =>
        requests().delete(`${baseURL}${abort}/${conversationid}`, {
            ...config
        }),
}

export const UserApi = {
    get: (config: any) =>
        requests().get(`${baseURL}${user}`, {
            ...config
        }),
    put: (body: any, config: any) =>
        requests().put(`${baseURL}${user}`, body, {
            ...config
        }),
    delete: (config: any) =>
        requests().delete(`${baseURL}${user}`, {
            ...config
        }),
}

export const LoginApi = {
    login: (body: any, config: any) =>
        requests().post(`${baseURL}${login}`, body, {
            ...config
        }),
    logout: (config: any) =>
        requests().post(`${baseURL}${logout}`, {}, {
            ...config
        }),
    logoutAll: (config: any) =>
        requests().post(`${baseURL}${logoutAll}`, {}, {
            ...config
        }),
    changePassword: (body: any, config: any) =>
        requests().post(`${baseURL}${changePassword}`, body, {
            ...config
        }),
    resetPassword: (body: any, config: any) =>
        requests().post(`${baseURL}${resetPassword}`, body, {
            ...config
        }),
}
